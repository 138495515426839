import styled from '@emotion/styled';
import GlobalStyle from 'styles/GlobalStyle';
import { mobile } from 'styles/responsive';
import { createStars, shining } from 'styles/mixins';
import ToMainButton from 'components/atoms/button/ToMainButton';

const ReadyPageContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  height: 100vh;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
`;

const ReadyPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    margin-top: 50px;
  }
`;

const Stars = styled.div`
  .star {
    position: absolute;
    z-index: 1;
    box-shadow: 0px 0px 5px #fff;
    border-radius: 50%;
    background-color: #fff;
    animation-name: ${shining};
    animation-timing-function: ease;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  }

  ${createStars}
`;

const ReadyText = styled.div`
  color: #e3e8f3;
  font-size: 80px;
  font-weight: 600;

  span {
    background: #a9b8db;
    opacity: 0.7;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media (max-width: ${mobile}) {
    font-size: 30px;
  }
`;

export default function Ready() {
  const arr = Array.from({ length: 200 }, () => 0);
  return (
    <ReadyPageContainer>
      <GlobalStyle />
      <ReadyPageWrapper>
        <Stars>
          {arr.map((n, i) => (
            <div className="star" key={i}></div>
          ))}
        </Stars>
        <ReadyText>
          페이지 <span>준비중</span> 입니다
        </ReadyText>
        <ToMainButton />
      </ReadyPageWrapper>
    </ReadyPageContainer>
  );
}
