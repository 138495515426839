import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { mobile } from 'styles/responsive';
import { GatsbyLinkType } from 'types/Link.types';

const GoToMainBtnWrapper = styled(({ ...props }: GatsbyLinkType) => (
  <Link {...props} />
))`
  position: relative;
  overflow: hidden;
  padding: 15px 30px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  color: #ffffff;
  font-size: 20px;
  text-decoration: none;
  letter-spacing: 3px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.4),
      transparent
    );
    transition: 0.8s;
    transition-delay: 0.4s;
  }

  &:hover::after {
    left: 100%;
  }

  &:hover {
    color: #ffffff;

    span:nth-of-type(1) {
      width: 100%;
      transform: translateX(100%);
    }
    span:nth-of-type(3) {
      width: 100%;
      transform: translateX(-100%);
    }
    span:nth-of-type(2) {
      height: 100%;
      transform: translateY(100%);
    }
    span:nth-of-type(4) {
      height: 100%;
      transform: translateY(-100%);
    }
  }

  span {
    display: block;
    position: absolute;
    transition: 0.5s ease;
  }

  span:nth-of-type(1) {
    top: 0;
    left: 0;
    width: 0;
    height: 1px;
    background: linear-gradient(-225deg, #7085b6 0%, #87a7d9 50%, #def3f8 100%);
  }

  span:nth-of-type(3) {
    bottom: 0;
    right: 0;
    width: 0;
    height: 1px;
    background: linear-gradient(-225deg, #7085b6 0%, #87a7d9 50%, #def3f8 100%);
  }

  span:nth-of-type(2) {
    top: 0;
    left: 0;
    width: 1px;
    height: 0;
    background: linear-gradient(-225deg, #7085b6 0%, #87a7d9 50%, #def3f8 100%);
  }

  span:nth-of-type(4) {
    bottom: 0;
    right: 0;
    width: 1px;
    height: 0;
    background: linear-gradient(-225deg, #7085b6 0%, #87a7d9 50%, #def3f8 100%);
  }

  @media (max-width: ${mobile}) {
    padding: 10px 15px;
    font-size: 16px;
  }
`;

export default function ToMainButton() {
  return (
    <GoToMainBtnWrapper to={`/`}>
      <span />
      <span />
      <span />
      <span />
      메인으로
    </GoToMainBtnWrapper>
  );
}
